import React from 'react';
import { useNavigate } from 'react-router-dom';
import Hero from '../components/Hero';
import Cards from '../components/Cards';
import Gallery from '../components/Gallery';
import ContactForm from '../components/ContactForm';
import ServicesSection from '../components/ServicesSection';
import Partners from '../components/Partners';
import FAQ from '../components/FAQ';
import './Home.css';

const Home = () => {
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate('/about');
  };

  return (
    <div>
      <Hero />
      <section className="home-about">
        <div className="home-about-text">
          <h2>RRETH NESH</h2>
          <div className="that"></div>
          <p>
            E themeluar në vitin 2022, Solar 2 është një kompani udhëheqëse që specializohet në implementimin e paneleve solare. Me një angazhim për zgjidhje energjetike të qëndrueshme, kemi përfunduar me sukses mbi 20 projekte, duke ndihmuar biznese dhe shtëpi të kalojnë në burime të ripërtëritshme energjie.
          </p>
          <p>
            Të vendosur në qendër të Prishtinës, zyrat tona shërbejnë si qendra për ekipin tonë të dedikuar të profesionistëve. Në Solar 2, ne e vëmë përparësinë në cilësi dhe efikasitet në çdo projekt, duke siguruar që klientët tanë të marrin shërbimin dhe rezultatet më të mira të mundshme.
          </p>
          <p>
            Misioni ynë është të promovojmë përdorimin e energjisë solare dhe të kontribuojmë për një të ardhme më të gjelbër dhe më të qëndrueshme. Besoni Solar 2 për të gjitha nevojat tuaja për panelet solare dhe bashkohuni me ne në ndikimin pozitiv në mjedis.
          </p>
          <button className="btn1" onClick={handleReadMoreClick}>Lexo më shumë</button>
        </div>
        <div className="home-about-image"></div>
      </section>
      <ServicesSection />
      <h1 className="projektet">Projektet tona</h1>
      <Cards type="post" limit={4} />
      <Partners />
      <FAQ />
      <h1 className="projektet">Galeria</h1>
      <Gallery />
      <ContactForm/>
    </div>

  );
};

export default Home;
