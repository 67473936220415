import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../components/Logo.png';
import './Navbar.css';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsMenuVisible(false);
    const menu = document.querySelector('.mobile-menu');
    menu.classList.add('close-animation');
    setTimeout(() => {
      menu.classList.remove('close-animation');
    }, 500);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-item active' : 'nav-item';
  };

  const getMobileNavLinkClass = (path) => {
    return location.pathname === path ? 'mobile-nav-item active' : 'mobile-nav-item';
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar-wrapper ${isSticky ? 'sticky' : ''}`}>
      <div className="navbar-container">
        <div className="nav-logo">
          <a href="/">
            <img src={Logo} alt="Logo" className="logo-image" />
          </a>
        </div>
        <div className="nav-menu">
          <Link className={getNavLinkClass('/')} to="/">Home</Link>
          <Link className={getNavLinkClass('/about')} to="/about">Rreth Nesh</Link>
          <Link className={getNavLinkClass('/services')} to="/services">Serviset</Link>
          <Link className={getNavLinkClass('/projects')} to="/projects">Projektet</Link>
          <Link className={getNavLinkClass('/contact')} to="/contact">Kontakti</Link>
        </div>
        <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className={`mobile-menu ${isMenuVisible ? 'open' : ''}`}>
          <div className="mobile-menu-header">
            <div className="close-icon" onClick={closeMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>
          <Link className={getMobileNavLinkClass('/')} to="/" onClick={closeMenu}>Home</Link>
          <Link className={getMobileNavLinkClass('/about')} to="/about" onClick={closeMenu}>Rreth Nesh</Link>
          <Link className={getMobileNavLinkClass('/services')} to="/services" onClick={closeMenu}>Serviset</Link>
          <Link className={getMobileNavLinkClass('/projects')} to="/projects" onClick={closeMenu}>Projektet</Link>
          <Link className={getMobileNavLinkClass('/contact')} to="/contact" onClick={closeMenu}>Kontakti</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
