import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import HeroImage1 from './Hero1.webp';
import HeroImage2 from './Hero2.webp';
import HeroImage3 from './Hero3.webp';
import HeroImage4 from './Hero4.webp';
import './Hero.css';

const HeroCarousel = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/about'); // Navigate to the About.js page
  };

  return (
    <Carousel>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block w-100 hero-image"
            src={HeroImage1}
            alt="First slide"
          />
          <div className="carousel-overlay"></div>
          <Carousel.Caption>
            <h3>Përfitoni nga fuqia e diellit me SOLAR2</h3>
            <p></p>
            <button className="btn btn-primary" onClick={handleButtonClick}>
              Mëso më shumë
            </button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block w-100 hero-image"
            src={HeroImage2}
            alt="Second slide"
          />
          <div className="carousel-overlay"></div>
          <Carousel.Caption>
            <h3>Përfitoni nga fuqia e diellit me SOLAR2</h3>
            <p></p>
            <button className="btn btn-primary" onClick={handleButtonClick}>
              Mëso më shumë
            </button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block w-100 hero-image"
            src={HeroImage3}
            alt="Third slide"
          />
          <div className="carousel-overlay"></div>
          <Carousel.Caption>
            <h3>Përfitoni nga fuqia e diellit me SOLAR2</h3>
            <p></p>
            <button className="btn btn-primary" onClick={handleButtonClick}>
              Mëso më shumë
            </button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block w-100 hero-image"
            src={HeroImage4}
            alt="Fourth slide"
          />
          <div className="carousel-overlay"></div>
          <Carousel.Caption>
            <h3>Përfitoni nga fuqia e diellit me SOLAR2</h3>
            <p></p>
            <button className="btn btn-primary" onClick={handleButtonClick}>
              Mëso më shumë
            </button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default HeroCarousel;
