import React from 'react';
import './Services.css';
import Instalimi from './Instalimi.png';
import Ruajtja from './Ruajtja.jpeg';
import Konsulenca from './Konsulenca.jpg';
import EcoFriendly from './EcoFriendly.jpg';

const servicesData = [
  {
    image: Instalimi,
    title: 'Instalimi i Paneleve Solare',
    description: 'Ne ofrojmë shërbime profesionale për instalimin e paneleve diellore për t\'ju ndihmuar të përfitoni nga fuqia e diellit për të ulur kostot e energjisë dhe të kontribuoni në mbrojtjen e mjedisit. Instalimi i paneleve solare është një investim i zgjuar që mund të ulë ndjeshëm faturat tuaja të energjisë dhe të rrisë vlerën e pronës suaj. Ekspertët tanë janë të trajnuar për të siguruar një instalim të sigurt dhe efikas, duke përdorur teknologjinë më të fundit për të maksimizuar prodhimin e energjisë diellore.',
    link: '#'
  },
  {
    image: Ruajtja,
    title: 'Zgjidhje për Ruajtjen e Energjisë',
    description: 'Zgjidhjet tona për ruajtjen e energjisë sigurojnë që të keni energji kur ju nevojitet, edhe gjatë ndërprerjeve. Teknologjia jonë për ruajtjen e energjisë siguron që të keni akses në energji të qëndrueshme, edhe kur rrjeti i energjisë elektrike dështon. Me sistemet tona të avancuara të ruajtjes së energjisë, ju mund të ruani energjinë e prodhuar nga panelet diellore për përdorim të mëvonshëm, duke siguruar një furnizim të qëndrueshëm dhe të besueshëm me energji elektrike.',
    link: '#'
  },
  {
    image: Konsulenca,
    title: 'Konsulencë për Energjinë e Ripërtëritshme',
    description: 'Këshillim profesional për integrimin e burimeve të energjisë së ripërtëritshme në shtëpinë ose biznesin tuaj. Ekspertët tanë ju ndihmojnë të përfitoni nga teknologjitë e energjisë së ripërtëritshme, duke ofruar këshilla të personalizuara për instalimet në shtëpi ose në biznese. Ne ofrojmë analiza të detajuara dhe plane të personalizuara për të maksimizuar përfitimet nga energjia e ripërtëritshme, duke përfshirë energjinë diellore, të erës dhe të ujit.',
    link: '#'
  },
  {
    image: EcoFriendly,
    title: 'Zgjidhje Eco-friendly',
    description: 'Ne ofrojmë një gamë zgjidhjesh miqësore me mjedisin për të reduktuar gjurmën tuaj të karbonit dhe për të promovuar qëndrueshmërinë. Zgjidhjet tona ekologjike përfshijnë produkte dhe shërbime që ndihmojnë në uljen e ndotjes dhe në ruajtjen e burimeve natyrore. Ne ofrojmë një gamë të zgjidhjeve ekologjike për t\'ju ndihmuar të reduktoni gjurmën tuaj të karbonit dhe të kontribuoni në mbrojtjen e mjedisit, duke përfshirë teknologji të avancuara për menaxhimin e mbetjeve dhe përdorimin e materialeve të riciklueshme.',
    link: '#'
  }
];

const Services = () => {
  return (
    <div className="full-width-container">
      <div className="new-service-container">
        <h1>Shërbimet Tona</h1>
        <div className="new-services-list">
          {servicesData.map((service, index) => (
            <div className={`new-service-item ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
              <div className="new-image-container">
                <img src={service.image} alt={service.title} />
              </div>
              <div className="new-service-text">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
