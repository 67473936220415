import React from 'react';
import './Footer.css';
import Logo from './Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-container">
          <div className="footer-section">
            <a href="/">
              <img src={Logo} alt="Logo" className="footer-logo" />
            </a>
          </div>
          <div className="footer-section">
            <h4>Na Kontaktoni</h4>
            <a href="mailto:info@solar2.eu">
              <p><FontAwesomeIcon icon={faEnvelope} className="icon" /> Email: info@solar2.eu</p>
            </a>
            <a href="tel:+38345883314">
              <p><FontAwesomeIcon icon={faPhone} className="icon" /> Phone: +383 45883314</p>
            </a>
          </div>
          <div className="footer-section">
            <h4>Na Ndiqni</h4>
            <p>Lidhu me ne në rrjetet sociale:</p>
            <div className="social-icons">
              <a href="https://www.facebook.com/profile.php?id=100063813109641" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="icon" />
              </a>
              <a href="https://www.instagram.com/solar2group/?igsh=MWU0djN6emIzNnN5NQ%3D%3D" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="icon" />
              </a>
              <a href="https://www.linkedin.com/company/solar2group" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="icon" />
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h4>Quick Links</h4>
            <a href="/">Home</a><br />
            <a href="Projects">Projects</a><br />
            <a href="About">About</a><br />
            <a href="Contact">Contact</a><br />
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Solar2. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
