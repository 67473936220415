import React, { useState } from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import Foto1 from './Foto1.jpg';
import Foto2 from './Foto2.jpg';
import Foto3 from './Foto3.jpg';
import Foto4 from './Foto4.jpg';
import Foto5 from './Foto5.jpg';
import Foto6 from './Foto6.jpg';
import Foto7 from './Foto7.jpg';
import Foto8 from './Foto8.jpg';
import './Gallery.css';

const images = [
  { src: Foto1 },
  { src: Foto2 },
  { src: Foto3 },
  { src: Foto4 },
  { src: Foto5 },
  { src: Foto6 },
  { src: Foto7 },
  { src: Foto8 }
];

export default function Gallery() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto1}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto1'
              onClick={() => openLightbox(0)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
        </MDBCol>

        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto2}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto2'
              onClick={() => openLightbox(1)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
        </MDBCol>

        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto3}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto3'
              onClick={() => openLightbox(2)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
        </MDBCol>

        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto4}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto4'
              onClick={() => openLightbox(3)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
        </MDBCol>

        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto5}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto5'
              onClick={() => openLightbox(4)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
          
        </MDBCol>
        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto6}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto6'
              onClick={() => openLightbox(5)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
          
        </MDBCol>
        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto7}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto7'
              onClick={() => openLightbox(6)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
          
        </MDBCol>
        <MDBCol lg={3} md={6} sm={12} className='mb-4 mb-lg-0'>
          <div className="gallery-item">
            <img
              src={Foto8}
              className='w-100 shadow-1-strong rounded mb-4'
              alt='Foto8'
              onClick={() => openLightbox(7)}
            />
            <div className="overlay">
              <p>view</p>
            </div>
          </div>
          
        </MDBCol>
      </MDBRow>

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </MDBContainer>
  );
}
