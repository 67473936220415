import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'; 
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Emri: '',
    Mbiemri: '',
    Telefoni: '',
    email: '',
    Mesazhi: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
        'YOUR_TEMPLATE_ID', // Replace with your EmailJS template ID
        e.target,
        'YOUR_USER_ID' // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          setSuccessMessage('Message sent successfully!');
          setFormData({ Emri: '', Mbiemri: '', Telefoni: '', email: '', Mesazhi: '' });
        },
        (error) => {
          setErrorMessage('Failed to send the message. Please try again.');
        }
      );
  };

  return (
    <div className="contact-us-section">
    <div className="contact-us-container">
      <div className="contact-info">
        <h2>Informacioni i Kontaktit</h2>
        <div className="info-item">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
          <p>
            <a
              href="https://maps.app.goo.gl/PgFvpmwQHja23hnX9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prishtinë , Kosovë
            </a>
          </p>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faPhone} className="icon" />
          <p>
            <a href="tel:+38345883314">+383 45883314</a>
          </p>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <p>
            <a href="mailto: info@solar2.eu"> info@solar2.eu</a>
          </p>
        </div>
      </div>
      <div className="contact-form">
        <h2>Na Kontaktoni</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">Emri</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.Emri}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Mbiemri</label>
            <input
              type="text"
              id="surname"
              name="surname"
              value={formData.Mbiemri}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefoni</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.Telefoni}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mesazhi</label>
            <textarea
              id="message"
              name="message"
              value={formData.Mesazhi}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit-btn">Send</button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default ContactForm;
