
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import DetailPage from './components/DetailPage';
import Footer from './components/Footer';
import FAQ from './components/FAQ';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './pages/About';
import '@fortawesome/fontawesome-free/css/all.min.css';

const App = () => {
    const location = useLocation();

    return (
        <div className="App">
            <Navbar />
            <TransitionGroup className="page-transition-wrapper">
                <CSSTransition key={location.key} classNames="page-transition" timeout={300}>
                    <div className="page-transition">
                        <Routes location={location}>
                            <Route path="/" element={<Home />} />
                            <Route path="/About" element={<About />} />
                            <Route path="/Services" element={<Services />} />
                            <Route path="/Projects" element={<Projects />} />
                            <Route path="/Contact" element={<Contact />} />
                            <Route path="/Detail/:slug" element={<DetailPage />} />
                            <Route path="/FAQ" element={<FAQ />} /> 
                            {/* Fallback route */}
                        </Routes>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <Footer />
        </div>
    );
};

export default App;
