import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../sanityClient'; // Adjust the path if needed
import imageUrlBuilder from '@sanity/image-url';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import './DetailPage.css';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const DetailPage = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await sanityClient.fetch(
          `*[_type == 'post' && slug.current == $slug][0]`,
          { slug }
        );
        setPost(response);
      } catch (error) {
        console.error('Error fetching post:', error.message);
      }
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    // Ensure the page scrolls to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="detail-page">
      <h2 className="title">{post.title}</h2>
      <div className="meta">
        <p className="author">Published by {post.author?.name}</p>
      </div>
      {post.mainImage && (
        <img className="main-image" src={urlFor(post.mainImage).url()} alt={post.mainImage.alt} />
      )}
      <div className="text-content">
        {/* Render text content */}
        {post.body.map((block, index) => (
          block._type === 'block' && (
            <p key={index} className="text-block">{block.children[0].text}</p>
          )
        ))}
      </div>
      <div className="gallery">
        {/* Render gallery */}
        {post.body.map((block, index) => (
          block._type === 'image' && (
            <div key={index} className="gallery-item" onClick={() => openLightbox(index)}>
              <img src={urlFor(block).url()} alt={block.alt} className="gallery-image" />
            </div>
          )
        ))}
      </div>
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={post.body.filter((block) => block._type === 'image').map((block) => ({
            src: urlFor(block).url(),
            alt: block.alt
          }))}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </div>
  );
};

export default DetailPage;
