import React, { useState } from 'react';
import './FAQ.css'; 
import faqImage from './faq-image.png'; 

const FAQ = () => {
  const faqData = [
    { question: 'Si funksionojnë panelet solare?', answer: 'Panelet solare shndërrojnë dritën e diellit në elektricitet duke përdorur qelizat fotovoltaike. Këto qeliza thithin dritën e diellit dhe krijojnë një rrymë elektrike që mund të furnizojë shtëpinë tuaj ose të dërgohet në rrjet.' },
    { question: 'Cilat janë përfitimet e instalimit të paneleve solare?', answer: 'Panelet solare ulin faturat tuaja të energjisë elektrike, rrisin vlerën e pronës tuaj dhe ndihmojnë në uljen e emetimeve të karbonit. Për më tepër, ju mund të jeni të kualifikuar për stimuj dhe kredi tatimore nga qeveria.' },
    { question: 'Sa zgjasin panelet solare?', answer: 'Shumica e paneleve solare kanë një garanci prej 25-30 vitesh dhe mund të vazhdojnë të prodhojnë elektricitet në mënyrë efikase edhe pas kësaj periudhe, megjithëse efikasiteti i tyre mund të bjerë gradualisht.' },
    { question: 'A funksionojnë panelet solare gjatë ditëve me re ose shi?', answer: 'Po, panelet solare mund të gjenerojnë ende elektricitet gjatë ditëve me re ose shi, megjithëse efikasiteti i tyre është më i ulët krahasuar me ditët me diell. Ato funksionojnë më mirë nën rrezet e drejtpërdrejta të diellit.' },
    { question: 'Sa kushtojnë panelet solare?', answer: 'Kostoja e paneleve solare ndryshon në varësi të madhësisë së sistemit dhe vendndodhjes suaj. Mesatarisht, një instalim solare për banesat mund të kushtojë midis 10,000 dhe 20,000 dollarë para stimujve.' },
    { question: 'A mund ta shes tepricën e energjisë elektrike në rrjet?', answer: 'Po, shumë zona ofrojnë programe të matjes neto që ju lejojnë të shisni çdo tepricë të energjisë elektrike që sistemi juaj gjeneron përsëri në rrjet, duke ulur më tej koston tuaj të energjisë.' },
    { question: 'Sa mirëmbajtje kërkojnë panelet solare?', answer: 'Panelet solare kërkojnë shumë pak mirëmbajtje. Ato duhet të inspektohen dhe pastrohen periodikisht për të siguruar që janë të lira nga papastërtitë dhe mbeturinat, të cilat mund të ulin efikasitetin e tyre.' }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">Pyetjet më të Shpeshta</h2>
      <div className="faq-content">
        <div className="faq-image">
          <img src={faqImage} alt="FAQ" />
        </div>
        <div className="faq-section">
          {faqData.length > 0 ? (
            faqData.map((faq, index) => (
              <div key={index} className="faq-item">
                <div 
                  className={`faq-question ${openIndex === index ? 'open' : ''}`} 
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                  <span className="faq-icon">{openIndex === index ? '−' : '+'}</span>
                </div>
                <div className={`faq-answer ${openIndex === index ? 'show' : ''}`}>
                  {faq.answer}
                </div>
              </div>
            ))
          ) : (
            <p>Nuk ka pyetje të shpeshta të disponueshme.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
