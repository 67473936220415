import React from 'react';
import Cards from '../components/Cards';
import '../components/Cards.css'; 
import './Projects.css'

const Projects = () => {
  return (
    <div>
       <div className="test">
        <h1>Projektet Tona</h1>
        <p>Solar2 është lider në ofrimin e zgjidhjeve të energjisë diellore dhe ka ndihmuar në përmirësimin e efikasitetit të energjisë dhe reduktimin e kostove për shumë klientë. Duke shqyrtuar projektet tona, do të kuptoni më mirë se si mund të përfitoni nga teknologjia jonë e avancuar dhe përkushtimi ynë për shërbimin e shkëlqyer.

Shfletoni  projektet tona për të parë disa nga arritjet tona më të mëdha dhe për të marrë frymëzim për projektin tuaj të ardhshëm. Nëse keni pyetje ose dëshironi të diskutoni një projekt të mundshëm, mos hezitoni të na kontaktoni. Ekipi ynë është gjithmonë i gatshëm për të ofruar mbështetje dhe këshilla për çdo hap të rrugës.

</p>

  
  
   </div>
   <Cards type="project" limit={0} />
    </div>
  );
};

export default Projects;
