import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import 'font-awesome/css/font-awesome.min.css';
import client from './sanityClient'; // Ensure this path is correct

const container = document.getElementById('root');
const root = createRoot(container);

const AppWrapper = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    client.fetch('*[_type == "yourDocumentType"]') // Replace with your actual document type
      .then((data) => setData(data))
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <App data={data} />;
};

root.render(
  <React.StrictMode>
    <Router>
      <AppWrapper />
    </Router>
  </React.StrictMode>
);
