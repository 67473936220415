import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSolarPanel, faBatteryFull, faWind, faLeaf } from '@fortawesome/free-solid-svg-icons';
import './ServicesSection.css'; 

function ServicesSection() {      
  return (
    <div className="service-container">
    <div className="services-section">
      <h2>Shërbimet Tona</h2>
      <div className="services-list">
        <div className="service-item">
          <FontAwesomeIcon icon={faSolarPanel} size="3x" className="icon-solar" />
          <h3>Instalimi i Paneleve Solare</h3>
          <p>Ne ofrojmë shërbime profesionale për instalimin e paneleve diellore për t'ju ndihmuar të përfitoni nga fuqia e diellit.</p>
        </div>
        <div className="service-item">
          <FontAwesomeIcon icon={faBatteryFull} size="3x" className="icon-battery" />
          <h3>Zgjidhje për Ruajtjen e Energjisë</h3>
          <p>Zgjidhjet tona për ruajtjen e energjisë sigurojnë që të keni energji kur ju nevojitet, edhe gjatë ndërprerjeve.</p>
        </div>
        <div className="service-item">
          <FontAwesomeIcon icon={faWind} size="3x" className="icon-wind" />
          <h3>Konsulencë për Energjinë e Ripërtëritshme</h3>
          <p>Këshillim profesional për integrimin e burimeve të energjisë së ripërtëritshme në shtëpinë ose biznesin tuaj.</p>
        </div>
        <div className="service-item">
          <FontAwesomeIcon icon={faLeaf} size="3x" className="icon-leaf" />
          <h3>Zgjidhje Eco-friendly </h3>
          <p>Ne ofrojmë një gamë zgjidhjesh miqësore me mjedisin për të reduktuar gjurmën tuaj të karbonit dhe për të promovuar qëndrueshmërinë..</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ServicesSection;
