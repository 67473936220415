import React from 'react';
import './Partners.css';
import Partner1 from './NLB.svg';
import Partner2 from './logo_procredit.png';
import Partner3 from './MC_FOOD.png';
import Partner4 from './NORA_MARKET.jpg';
import Partner5 from './Panorama_Residence.jpg';
import Partner6 from './Batllava_Premium_Resort.jpg';

function Partners() {
  const partners = [
    { name: 'NLB BANKA', logo: Partner1 },
    { name: 'PROCREDIT BANK', logo: Partner2 },
    { name: 'MC FOOD', logo: Partner3 },
    { name: 'NORA MARKET', logo: Partner4 },
    { name: 'PANORAMA RESIDENCE', logo: Partner5 },
    { name: 'BATLLAVA PREMIUM RESORT', logo: Partner6 },
  ];

  return (
    <div className="partners-container">
      <div className="partners-section">
      <h2 className="projektet">Partnerët Tanë</h2>
        <div className="partners-list">
          {/* Duplicate the partner items to ensure continuous scrolling */}
          {partners.concat(partners).map((partner, index) => (
            <div className="partners-item" key={index}>
              <img src={partner.logo} alt={partner.name} />
              <p>{partner.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Partners;
