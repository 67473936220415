import sanityClient from '@sanity/client';

const client = sanityClient({
  projectId: 'zrs655dj',
  dataset: 'production',
  apiVersion: '2023-06-10',
  useCdn: false,
});


export default client; 
