import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faSun, faBolt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './About.css';
import AboutImage from '../components/about-page.webp';
import StaffSection from '../components/StaffSection';

const About = () => {
  const navigate = useNavigate();

  const goToContactPage = () => {
    navigate('/contact');
  };

  return (
    <div className="about-outer-container">
      <div className="about-header">
        <h2 className="about-heading">Rreth Solar2</h2>
      </div>
      <div className="line"></div>

      <div className="about-container">
        <section className="about-hero">
          <img src={AboutImage} alt="About Us" className="about-image" />
        </section>

        <section className="about-content">
          <div className="about-text">
            <p>
              Në Solar2, jemi të përkushtuar në përdorimin e energjisë së pastër për të ndërtuar një të ardhme të qëndrueshme.
              Ne ofrojmë zgjidhje solare që ndihmojnë individët, bizneset dhe komunitetet të kalojnë drejt një mënyre më të
              përgjegjshme jetese, duke ulur kostot e energjisë dhe duke zvogëluar gjurmën e tyre karbone. Si liderë në
              instalimin e paneleve solare, ne përdorim teknologji të avancuar për të ofruar sisteme të besueshme dhe miqësore
              me mjedisin. Me Solar2, ju investoni në një të ardhme më të gjelbër dhe më të ndritshme, duke kontribuar për një
              planet më të pastër për brezat e ardhshëm.
            </p>
          </div>
          <div className="about-icons">
            <div className="about-icon eco-friendly">
              <FontAwesomeIcon icon={faLeaf} className="about-page-icon" />
              <h3 className="icon-title">Eco-friendly</h3>
            </div>
            <div className="about-icon solar-energy">
              <FontAwesomeIcon icon={faSun} className="about-page-icon" />
              <h3 className="icon-title">Energjia e Diellit</h3>
            </div>
            <div className="about-icon energy-saving">
              <FontAwesomeIcon icon={faBolt} className="about-page-icon" />
              <h3 className="icon-title">Kursimi i Energjisë</h3>
            </div>
          </div>
        </section>
      </div>

      

      <section className="why-choose-us">
        <h2 className="why-choose-heading">Pse Ne?</h2>
        <div className="why-choose-content">
          <div className="why-choose-item">
            <div className="number">1.</div>
            <div className="why-choose-text">
              <h3 className="why-choose-title">Kualitet i Lartë</h3>
              <p>
                Ne ofrojmë produkte dhe shërbime të nivelit më të lartë për të siguruar performancë dhe qëndrueshmëri maksimale.
              </p>
            </div>
          </div>
          <div className="why-choose-item">
            <div className="number">2.</div>
            <div className="why-choose-text">
              <h3 className="why-choose-title">Shërbim i Përsosur</h3>
              <p>
                Ekipi ynë është i përkushtuar për të ofruar mbështetje të shkëlqyer dhe shërbim të personalizuar për çdo klient.
              </p>
            </div>
          </div>
          <div className="why-choose-item">
            <div className="number">3.</div>
            <div className="why-choose-text">
              <h3 className="why-choose-title">Inovacion</h3>
              <p>
                Ne përdorim teknologji të fundit dhe metodologji të avancuara për të ofruar zgjidhje të përparuara dhe efektive.
              </p>

            </div>
          </div>
        </div>
      </section>

      <div className="about-3-bg">
        <div className="about-3">
          <h1>Na kontaktoni për të diskutuar se si mund t'ju ndihmojmë!</h1>
          <p>Kemi kënaqësinë të mirëpresim klientët që të bashkohen me ne.</p>
          <button onClick={goToContactPage} className="contact-button">
                Na Kontaktoni
              </button>
        </div>
      </div>
      <StaffSection/>
    </div>
   
  );
};

export default About;
