import React from 'react';
import './StaffSection.css';
import ErzenKrasniqiImage from './Placeholder.png'; 
import ShpendSfishtaImage from './Placeholder.png'; 
import DurimKrasniqiImage from './Placeholder.png'; 

const staffMembers = [
  { 
    name: 'Erzen Krasniqi', 
    role: 'CEO', 
    image: ErzenKrasniqiImage,
    social: {
      facebook: 'https://www.facebook.com/erzen.krasniqi.75',
      instagram: 'https://www.instagram.com/erzen_1?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
      linkedin: 'https://www.linkedin.com/in/erzen-krasniqi-b24836117/',
    },
  },
  { 
    name: 'Shpend Sfishta', 
    role: 'CTO', 
    image: ShpendSfishtaImage,
    social: {
      facebook: 'https://www.facebook.com/shpendsfishta',
      instagram: 'https://www.instagram.com/shpendsfishta/?utm_source=ig_web_button_share_sheet',
      linkedin: '#',
    },
  },
  { 
    name: 'Durim Krasniqi', 
    role: 'Lead Engineer', 
    image: DurimKrasniqiImage,
    social: {
      facebook: 'https://www.facebook.com/durimkrasn',
      instagram: 'https://www.instagram.com/durimkk?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
      linkedin: 'https://www.linkedin.com/in/durim-krasniqi-b30416134/',
    },
  },
];

const StaffSection = () => {
  return (
    <div className="staff-section">
      <h2>Njihuni me Ekipin Tonë</h2>
      <div className="staff-grid">
        {staffMembers.map((staff, index) => (
          <div key={index} className="staff-card">
            <img src={staff.image} alt={`${staff.name}`} />
            <div className="staff-info">
              <h3>{staff.name}</h3>
              <p>{staff.role}</p>
              <div className="social-links">
                <a href={staff.social.facebook} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href={staff.social.instagram} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href={staff.social.linkedin} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaffSection;
