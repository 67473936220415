import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient'; // Adjust the path if needed
import { Link } from 'react-router-dom';
import imageUrlBuilder from '@sanity/image-url';
import { Container, Row, Col } from 'react-bootstrap';
import './Cards.css'; // Custom CSS for additional styling

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Cards = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "post"]{_id, title, slug, mainImage}')
      .then((data) => {
        setPosts(data);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  return (
    <div className="card-section">
    <Container fluid>
      <Row className="cards-container">
        {posts.map((post) => (
          <Col key={post._id} xs={12} sm={6} md={4} lg={3}>
            <Link to={`/detail/${post.slug.current}`} className="card-link">
              <div className="card" style={{ backgroundImage: `url(${urlFor(post.mainImage).url()})` }}>
                <div className="card-content">
                  <h2>{post.title}</h2>
                  
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
  );
};

export default Cards;
